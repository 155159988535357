export default {
    CODE:'Code',
    ACCOUNTS_LIST: "Liste des comptes",
    ADD_TRANSACTION: "Ajouter une transaction",
    TRANSACTIONS: "Transactions",
    ACCOUNTS: "Comptes",
    DPA_ACCOUNT_ADDED: "Compte ajouté",
    EDIT_ACCOUNT: "Modifier le compte",
    ADD_ACCOUNT: "Ajouter un compte",
    ADD_DPA_ACCOUNT: "Ajouter un compte",
    ACCOUNT_UPDATED: "Compte mis à jour",
    DELETE_THIS_ACCOUNT: "Supprimer ce compte ?",
    ACCOUNT_DELETED: "Compte supprimé",
    ACCOUNT_TYPE: "Type de compte",
    VIEW_ACCOUNT: "Compte DPA/PPA",
    RETURN_TO_FROM: "Retourner au formulaire",
    ISSUER_NUMBER: "Numéro d’émetteur",
    DATA_CENTER_CODE: "Code du centre de données",
    BANK_FORMAT: "Format de banque",
    FILE_NAME: "Nom du fichier",
    TRANSACTION_CALLBACK_URL: "Url de rappel",
    DEBIT: 'Débit',
    CREDIT: 'Crédit',
    PPA: 'Prélèvement pré-autorisé',
    DPA: 'Dépôt direct',
    VIEW_DPA_ACCOUNT: "Compte DPA/PPA",
    EDIT_DPA_ACCOUNT: "Modifier le compte",
  };
  