import permissions from "../constants/permissions";
import DpaModuleLayout from "@/views/Pages/DpaModule/DpaModuleLayout.vue";
import DpaModuleMenu from "@/views/Pages/DpaModule/DpaModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListDpaPage = () =>
  import("@/views/Pages/DpaModule/DpaTransactionsManagement/ListDpaPage.vue");

const ListDpaAccountsPage = () =>
  import("@/views/Pages/DpaModule/DpaAccountManagement/ListDpaAccountPage.vue");

const DpaPage = () => import("@/views/Pages/DpaModule/DpaPage.vue");

let dpaManagementPages = {
  path: "transactions",
  components: {
    default: DpaModuleLayout,
    SidebarSecondLevelMenu: DpaModuleMenu,
  },
  children: [
    {
      path: "",
      name: "ListDpaTransactions",
      components: { default: ListDpaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_DPA_TRANSACTIONS,
        hasLevel2: true,
      },
    },
  ],
};

let dpaAccountsPages = {
  path: "accounts",
  components: {
    default: DpaModuleLayout,
    SidebarSecondLevelMenu: DpaModuleMenu,
  },
  children: [
    {
      path: "",
      name: "ListDpaAccounts",
      components: { default: ListDpaAccountsPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_DPA_ACCOUNTS,
        hasLevel2: true,
      },
    },
  ],
};

const dpaModuleRoutes = [
  {
    path: "",
    components: {
      default: DpaModuleLayout,
      SidebarSecondLevelMenu: DpaModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Dpa Page",
        components: { default: DpaPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  dpaManagementPages,
  dpaAccountsPages
];

export default dpaModuleRoutes;
